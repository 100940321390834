<template>
  <div>
    <h1>Фейковая авторизация для разработки</h1>
    <input v-model="token" placeholder="Введите токен" />
    <button @click="fakeLogin">Войти</button>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useRegistrationStore } from '@/stores/registrationStore';

export default {
  name: 'FakeAuthPage',

  data() {
    return {
      token: ''
    };
  },

  methods: {
    async fakeLogin() {
      try {
        const userStore = useUserStore();
        const registrationStore = useRegistrationStore();
        userStore.fakeLogin(this.token);

        const pendingProgramId = localStorage.getItem('pendingProgramId');
        if (pendingProgramId) {
          const result = await registrationStore.checkRegistration(pendingProgramId);
          if (result.success && result.programId) {
            this.$router.push({ name: 'RegistrationPage', params: { programId: result.programId } });
          } else {
            alert(result.message || 'Ошибка при регистрации. Попробуйте ещё раз.');
            this.$router.push('/');
          }
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        console.error('Произошла ошибка в методе fakeLogin:', error);
        alert('Ошибка авторизации. Пожалуйста, попробуйте ещё раз.');
        this.$router.push('/');
      }
    },
  },

  mounted() {
    document.body.classList.add('login-body');
  },

  beforeUnmount() {
    document.body.classList.remove('login-body');
  }
};
</script>

